import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { getRedirectUrlFromParams } from '@nx/fire/login-modal'
import { getTranslate } from '@nx/translations'

import translate from '@web/components/auth/auth.translate.json'
import { MetaData } from '@web/components/common/MetaData'
import { ModalsDispatchContext } from '@web/components/common/ModalManager/ModalContext'
import { getUser, useGetUser } from '@web/helpers/api/user'
import { withRedirects } from '@web/helpers/utils'

const { publicRuntimeConfig } = getConfig()

const { text } = getTranslate(translate)

export function Login() {
  const { data: user } = useGetUser()
  const router = useRouter()
  const dispatch = useContext(ModalsDispatchContext)

  useEffect(() => {
    dispatch?.({
      type: 'OPEN',
      payload: {
        modal: 'LOGIN',
        props: {
          brand: 'bonhams',
          user,
        },
      },
    })
  }, [user, dispatch])

  useEffect(() => {
    if (!user) return

    window.location.assign(getRedirectUrlFromParams(router.query))
  }, [user, router.query])

  return (
    <MetaData name={text('login')} baseUrl={publicRuntimeConfig.WEB_BASE_URL} />
  )
}

const serverSideProps: GetServerSideProps = async (context) => {
  const user = await getUser(context.req?.cookies).catch(() => null)

  if (user) {
    return {
      redirect: {
        destination: getRedirectUrlFromParams(context.query),
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}

export const getServerSideProps = withRedirects(serverSideProps)
export default Login
